import { useOpenChat, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { GenericProblemMessage } from '../../organisms/generic-problem-message'
import { SubscriptionPageV2 } from './subscription-v2-page'

const SubscriptionV2NoPossibleStepPage = memo(function SubscriptionV2NoPossibleStepPage() {
  const openChat = useOpenChat()
  const translate = useTranslate()

  return (
    <SubscriptionPageV2>
      <GenericProblemMessage
        title={translate('problem_oops_title')}
        principalMessage={translate('problem_principal_message')}
        firstSubText={translate('problem_first_subtext')}
        secondSubText={translate('problem_second_subtext')}
        buttonText={translate('contact_customer_support')}
        onButtonClick={openChat}
        trackingId="contact_button"
      />
    </SubscriptionPageV2>
  )
})
export default SubscriptionV2NoPossibleStepPage
